/*--
/*  4.5 - Achieved CSS
/*----------------------------------------*/

// Achieved Year 
.achieved-year{
    padding-top: 57px;
    // Responsive
    @media #{$tablet-device}{
        padding-top: 0;
    }
    @media #{$large-mobile}{
        padding-top: 0;
    }
}

// Year Text 
.year-text{
    margin-top: 30px;

    & p{
        font-family: 'Hepta Slab', serif;
        font-size: 18px;
        font-weight: 400;
        color: $light;
    }
}

// Achieved Item 
.achieved-item{
    
    margin-top: 30px;

    & .sub-title{
        font-family: 'Hepta Slab', serif;
        font-size: 18px;
        font-weight: 200;
        color: $secondary;

        @media #{$small-mobile}{
            font-size: 14px;
        }
    }
    & .title{
        font-family: 'Hepta Slab', serif;
        font-size: 36px;
        font-weight: 700;
        color: rgba($white, .5);
        transition: $transition-base;

        @media #{$small-mobile}{
            font-size: 24px;
        }

        & span{
            color: $light;
        }
        &:hover{
            color: $light;
        }
    }
}








