@import url('https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@700&display=swap');
@import url("https://use.typekit.net/ccm0xov.css");
/*--
/*  4.2 - Clients & About CSS
/*----------------------------------------*/

// About Section
.about-section{ 
    background-image: url("./about-images.svg");
    background-size: cover;
    background-color: #E0E4F1;
    background-repeat: no-repeat;
    position: relative;
}

// About Content 
.about-content{
    padding: 45px;
    bottom: 0;
    left: 0;
    & .title {
        font-family: 'Hepta Slab', serif;
        font-size: 45px;
        font-weight: 700;
        color: #1D243D;
        text-transform: uppercase;

        @media #{$small-mobile}{
            font-size: 28px;
        }
    }
    & .about-text{
        font-family: quasimoda,sans-serif;
        font-style: normal;
        font-size: 27px;
        line-height: 1.6;
        margin-top: 36px;

        @media #{$small-mobile}{
            font-size: 16px;
        }
    }
    & p{
        font-family: quasimoda,sans-serif;
        color: #1D243D;
        margin-bottom: 25px;
        font-size: 18px;
    }
    & .signechar{
        padding-top: 20px;
    }
    & .name{
        font-family: quasimoda,sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-family: $font-family-base;
        color: #1D243D;
        text-transform: uppercase;
        margin-top: 15px;
    }
}

// About Images 
.about-images{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;

    @media #{$tablet-device, $large-mobile}{
        position: relative;
        width: 720px;
        height: 727px;
        margin: 0 auto;
        margin-bottom: 40px;
        padding: 0 15px;
    }
    @media #{$large-mobile}{
        width: 540px;
        height: 545px;
    }
    @media #{$small-mobile}{
        width: 320px;
        height: 323px;
    }
}
