@import "~bootstrap/scss/bootstrap";
// @import "./bootstrap/functions";
@import "variabls";
// @import "./bootstrap/bootstrap";

@import "common";
@import "component";
@import "header";
@import "page";
@import "widget";
@import "footer";

.d-inline {
    font-family: 'Quasimoda', sans-serif;
    color: white;
}

.title {
    font-family: 'Hepta Slab', serif;
}

.footer-container {
    padding: 100px 100px;
    background-color: #C6CAEB;
}

.footer-title {
    color: #1D243D;
    font-family: 'Hepta Slab', sans-serif;
    font-weight: 200;
}

.project-details-body {
    padding-left: 110px;
    padding-right: 110px;
    font-family: 'quasimoda', sans-serif;
    color: white;
    font-size: 18px;
    text-align: justify;
}